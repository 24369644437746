import ComponentType from "../ota/componentType";

export default class Component {
    id: number;
    component_type_id: number;
    version: string;
    size: number;
    checksum: number;
    address: number;
    storage_uri: string;
    exact_uri: boolean;
    created_at: string;
    created_by: string;
    deleted: boolean;

    dependencies: Component[];
    showDependencies: boolean = false;

    component_type: ComponentType;

    constructor(component: Component = null) {
        this.dependencies = Array<Component>();
        if (component) {
            this.id = component.id;
            this.component_type_id = component.component_type_id;
            this.component_type = new ComponentType(component.component_type);
            this.version = component.version;
            this.size = component.size;
            this.checksum = component.checksum;
            this.address = component.address ?? null;
            this.storage_uri = component.storage_uri ?? '';
            this.exact_uri = component.exact_uri;
            this.created_at = component.created_at;
            this.created_by = component.created_by;
            this.deleted = component.deleted;
            if (component.dependencies) {
                this.dependencies = component.dependencies.map(c => {
                    return new Component(c);
                });
            }
        }
    }

    get versionSort() {
        if (this.version) {
            let e: number = 0;
            this.version.split('.').reverse().forEach(function (v, i) {
                return e += +v << (i * 8);
            });
            return e;
        }
    }
}